/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiCalendarioAuthWithCodeGet } from '../fn/calendario/api-calendario-auth-with-code-get';
import { ApiCalendarioAuthWithCodeGet$Params } from '../fn/calendario/api-calendario-auth-with-code-get';
import { apiCalendarioCalendarioEventiListGet } from '../fn/calendario/api-calendario-calendario-eventi-list-get';
import { ApiCalendarioCalendarioEventiListGet$Params } from '../fn/calendario/api-calendario-calendario-eventi-list-get';
import { apiCalendarioCalendarioListGet } from '../fn/calendario/api-calendario-calendario-list-get';
import { ApiCalendarioCalendarioListGet$Params } from '../fn/calendario/api-calendario-calendario-list-get';
import { apiCalendarioCalendarioSetVisibilitaListPost } from '../fn/calendario/api-calendario-calendario-set-visibilita-list-post';
import { ApiCalendarioCalendarioSetVisibilitaListPost$Params } from '../fn/calendario/api-calendario-calendario-set-visibilita-list-post';
import { apiCalendarioCalendarioVisibilitaListGet } from '../fn/calendario/api-calendario-calendario-visibilita-list-get';
import { ApiCalendarioCalendarioVisibilitaListGet$Params } from '../fn/calendario/api-calendario-calendario-visibilita-list-get';
import { apiCalendarioCorpoConfiguratoListGet } from '../fn/calendario/api-calendario-corpo-configurato-list-get';
import { ApiCalendarioCorpoConfiguratoListGet$Params } from '../fn/calendario/api-calendario-corpo-configurato-list-get';
import { apiCalendarioGetGoogleAuthUrlPost } from '../fn/calendario/api-calendario-get-google-auth-url-post';
import { ApiCalendarioGetGoogleAuthUrlPost$Params } from '../fn/calendario/api-calendario-get-google-auth-url-post';
import { CalendarioEventItemDto } from '../models/calendario-event-item-dto';
import { CalendarioItemDto } from '../models/calendario-item-dto';
import { CalendarioVisibilitaCorpoDto } from '../models/calendario-visibilita-corpo-dto';
import { CorpoListItemDto } from '../models/corpo-list-item-dto';

@Injectable({ providedIn: 'root' })
export class CalendarioService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiCalendarioCorpoConfiguratoListGet()` */
    static readonly ApiCalendarioCorpoConfiguratoListGetPath = '/api/calendario/corpo-configurato-list';

    /**
     * Carica la lista dei corpi abilitati al calendario.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCalendarioCorpoConfiguratoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCorpoConfiguratoListGet$Response(
        params?: ApiCalendarioCorpoConfiguratoListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<CorpoListItemDto>>> {
        return apiCalendarioCorpoConfiguratoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei corpi abilitati al calendario.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCalendarioCorpoConfiguratoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCorpoConfiguratoListGet(
        params?: ApiCalendarioCorpoConfiguratoListGet$Params,
        context?: HttpContext,
    ): Observable<Array<CorpoListItemDto>> {
        return this.apiCalendarioCorpoConfiguratoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<CorpoListItemDto>>): Array<CorpoListItemDto> => r.body),
        );
    }

    /** Path part for operation `apiCalendarioGetGoogleAuthUrlPost()` */
    static readonly ApiCalendarioGetGoogleAuthUrlPostPath = '/api/calendario/get-google-auth-url';

    /**
     * Genera un url per l'autenticazione alle API di google drive.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCalendarioGetGoogleAuthUrlPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCalendarioGetGoogleAuthUrlPost$Response(
        params: ApiCalendarioGetGoogleAuthUrlPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<string>> {
        return apiCalendarioGetGoogleAuthUrlPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Genera un url per l'autenticazione alle API di google drive.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCalendarioGetGoogleAuthUrlPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCalendarioGetGoogleAuthUrlPost(params: ApiCalendarioGetGoogleAuthUrlPost$Params, context?: HttpContext): Observable<string> {
        return this.apiCalendarioGetGoogleAuthUrlPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<string>): string => r.body),
        );
    }

    /** Path part for operation `apiCalendarioAuthWithCodeGet()` */
    static readonly ApiCalendarioAuthWithCodeGetPath = '/api/calendario/auth-with-code';

    /**
     * Esegue l'autenticazione tramite il codice ricevuto dal FE e salva il token e refresh token per l'utilizzo delle API di Google.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCalendarioAuthWithCodeGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioAuthWithCodeGet$Response(
        params: ApiCalendarioAuthWithCodeGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiCalendarioAuthWithCodeGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Esegue l'autenticazione tramite il codice ricevuto dal FE e salva il token e refresh token per l'utilizzo delle API di Google.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCalendarioAuthWithCodeGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioAuthWithCodeGet(params: ApiCalendarioAuthWithCodeGet$Params, context?: HttpContext): Observable<boolean> {
        return this.apiCalendarioAuthWithCodeGet$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }

    /** Path part for operation `apiCalendarioCalendarioVisibilitaListGet()` */
    static readonly ApiCalendarioCalendarioVisibilitaListGetPath = '/api/calendario/calendario-visibilita-list';

    /**
     * Carica la configurazione dei calendari visibili per il corpo richiesto.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCalendarioCalendarioVisibilitaListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCalendarioVisibilitaListGet$Response(
        params: ApiCalendarioCalendarioVisibilitaListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<CalendarioVisibilitaCorpoDto>>> {
        return apiCalendarioCalendarioVisibilitaListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la configurazione dei calendari visibili per il corpo richiesto.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCalendarioCalendarioVisibilitaListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCalendarioVisibilitaListGet(
        params: ApiCalendarioCalendarioVisibilitaListGet$Params,
        context?: HttpContext,
    ): Observable<Array<CalendarioVisibilitaCorpoDto>> {
        return this.apiCalendarioCalendarioVisibilitaListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<CalendarioVisibilitaCorpoDto>>): Array<CalendarioVisibilitaCorpoDto> => r.body),
        );
    }

    /** Path part for operation `apiCalendarioCalendarioSetVisibilitaListPost()` */
    static readonly ApiCalendarioCalendarioSetVisibilitaListPostPath = '/api/calendario/calendario-set-visibilita-list';

    /**
     * Imposta la visibilità dei calendari per il corpo indicato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCalendarioCalendarioSetVisibilitaListPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCalendarioCalendarioSetVisibilitaListPost$Response(
        params: ApiCalendarioCalendarioSetVisibilitaListPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<void>> {
        return apiCalendarioCalendarioSetVisibilitaListPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Imposta la visibilità dei calendari per il corpo indicato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCalendarioCalendarioSetVisibilitaListPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCalendarioCalendarioSetVisibilitaListPost(
        params: ApiCalendarioCalendarioSetVisibilitaListPost$Params,
        context?: HttpContext,
    ): Observable<void> {
        return this.apiCalendarioCalendarioSetVisibilitaListPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<void>): void => r.body),
        );
    }

    /** Path part for operation `apiCalendarioCalendarioListGet()` */
    static readonly ApiCalendarioCalendarioListGetPath = '/api/calendario/calendario-list';

    /**
     * Carica la lista dei calendari visibili per il corpo a cui l'utente appartiene.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCalendarioCalendarioListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCalendarioListGet$Response(
        params?: ApiCalendarioCalendarioListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<CalendarioItemDto>>> {
        return apiCalendarioCalendarioListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei calendari visibili per il corpo a cui l'utente appartiene.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCalendarioCalendarioListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCalendarioListGet(
        params?: ApiCalendarioCalendarioListGet$Params,
        context?: HttpContext,
    ): Observable<Array<CalendarioItemDto>> {
        return this.apiCalendarioCalendarioListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<CalendarioItemDto>>): Array<CalendarioItemDto> => r.body),
        );
    }

    /** Path part for operation `apiCalendarioCalendarioEventiListGet()` */
    static readonly ApiCalendarioCalendarioEventiListGetPath = '/api/calendario/calendario-eventi-list';

    /**
     * Carica la lista degli eventi visibili per il corpo a cui l'utente appartiene.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCalendarioCalendarioEventiListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCalendarioEventiListGet$Response(
        params: ApiCalendarioCalendarioEventiListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<CalendarioEventItemDto>>> {
        return apiCalendarioCalendarioEventiListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista degli eventi visibili per il corpo a cui l'utente appartiene.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCalendarioCalendarioEventiListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCalendarioCalendarioEventiListGet(
        params: ApiCalendarioCalendarioEventiListGet$Params,
        context?: HttpContext,
    ): Observable<Array<CalendarioEventItemDto>> {
        return this.apiCalendarioCalendarioEventiListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<CalendarioEventItemDto>>): Array<CalendarioEventItemDto> => r.body),
        );
    }
}
