import '@angular/common/locales/global/it';
import { DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, FormsModule } from '@angular/forms';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastModule } from 'primeng/toast';
import { initializeApp } from 'firebase/app';
import { BlockUIModule } from 'primeng/blockui';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppExceptionHandler } from '@core/errors/exception-handler';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AuthenticationProvider, LocalStorageProvider, NgxAuthUtilsModule, StorageProvider } from 'ngx-auth-utils';

import { environment } from '@env';
import { CoreModule } from '@core/core.module';
import { ApiModule } from '@core/http-gen/api.module';
import { AuthService } from '@core/http-gen/services';
import { ToastService } from '@core/services/toast.service';
import { AppTypeInterceptor } from '@core/interceptors/app-type.interceptor';
import { GradoHeaderInterceptor } from '@core/interceptors/grado-header.interceptor';
import { BackendAuthenticationProvider } from '@core/providers/backend-authentication.provider';
import { AppConfigGetter, AppConfigInizializer, AppConfigLoaderService } from '@core/loaders/config-loader.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NotFoundComponentComponent } from './modules/not-found-component/not-found-component.component';

initializeApp(environment.firebase);

@NgModule({
    declarations: [AppComponent, NotFoundComponentComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ApiModule,
        CoreModule,
        NgxAuthUtilsModule.forRoot({
            authenticationProvider: {
                provide: AuthenticationProvider,
                useClass: BackendAuthenticationProvider,
                deps: [AuthService],
            },
            storageProvider: {
                provide: StorageProvider,
                useClass: LocalStorageProvider,
            },
            homeUrl: '/',
            noAuthRedirectUrl: '/auth/login',
            sessionExpiredRedirectUrl: '/auth/login',
            refreshToken: true,
            unauthorizedUrlBlacklist: [AuthService.ApiAuthRefreshTokenPostPath],
        }),
        AppRoutingModule,
        FormsModule,
        BlockUIModule,
        FontAwesomeModule,
        ConfirmDialogModule,
        ToastModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ],
    providers: [
        FormBuilder,
        AppConfigLoaderService,
        AppConfigGetter,
        AppConfigInizializer,
        ConfirmationService,
        DecimalPipe,
        {
            provide: ErrorHandler,
            useClass: AppExceptionHandler,
        },
        ToastService,
        MessageService,
        { provide: LOCALE_ID, useValue: 'it' },
        //  Interceptor utilizzato per la gestione dei gradi multipli associati ad un utente (es. presidente, ispettore)
        { provide: HTTP_INTERCEPTORS, useClass: GradoHeaderInterceptor, multi: true },
        //  Interceptor utilizzato per l'aggiunta dell'header app-type
        { provide: HTTP_INTERCEPTORS, useClass: AppTypeInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
