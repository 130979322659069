/** ATTENZIONE!!! Questo enum va allineato con quello presente nel backend */
export enum Permesso {
    RubricaTelefonica = 1,
    Mappa = 2,
    Notifiche = 3,
    GestioneUtenti = 4,
    VigiliInArrivo = 5,
    GestioneRuoli = 6,
    StoricoSelettive = 7,
    GestioneCorpiEsterni = 8,
    MonitorSelettive = 9,
    MonitorCentrale = 10,
    DatiSensibiliSelettiva = 11,
    Domotica = 12,
    Calendario = 13,
    ConfigurazioneCaricamentoMultimediale = 14,
    CaricamentoMultimediale = 15,
    NotificaCaricamentoMultimediale = 16,
    ConfigurazioneAccessoGoogleCalendar = 17,
    PulsantiRispostaSelettive = 18,
}

export enum PermessoValore {
    Nessuno = 0,
    Lettura = 1 << 0, // 0001		- 1
    Modifica = 1 << 1, // 0010		- 2
    Scrittura = 1 << 2, // 0100		- 4
    Cancellazione = 1 << 3, // 1000	- 8
}

export enum PermessoTipo {
    Nessuno = 0,
    Lettura = 1 << 0, // 0001		- 1
    Modifica = 1 << 1, // 0010		- 2
    Scrittura = 1 << 2, // 0100		- 4
    Cancellazione = 1 << 3, // 1000	- 8
}

export enum TipoRuolo {
    Grado = 1,
    Mansione = 2,
    Custom = 3,
}

export enum PermessoProvenienza {
    Ruolo = 1,
    AssegnazioneDiretta = 2,
    Mancante = 3,
}

export enum TipoEntita {
    Volontario = 1,
    Corpo = 2,
    Unione = 3,
    Federazione = 4,
}
