/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AlertcallsService } from './services/alertcalls.service';
import { AuthService } from './services/auth.service';
import { CalendarioService } from './services/calendario.service';
import { CaricamentoMultimedialeService } from './services/caricamento-multimediale.service';
import { DispositivoMobileService } from './services/dispositivo-mobile.service';
import { LogService } from './services/log.service';
import { MarcatoreService } from './services/marcatore.service';
import { ModuloAggiuntivoService } from './services/modulo-aggiuntivo.service';
import { NotificaService } from './services/notifica.service';
import { NumeroUtileService } from './services/numero-utile.service';
import { OrganicoService } from './services/organico.service';
import { OtpService } from './services/otp.service';
import { PushNotificationService } from './services/push-notification.service';
import { RecapitoService } from './services/recapito.service';
import { ScadenzeService } from './services/scadenze.service';
import { SelettivaService } from './services/selettiva.service';
import { SessionService } from './services/session.service';
import { TestService } from './services/test.service';
import { TetraService } from './services/tetra.service';
import { ApiService } from './services/api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        AlertcallsService,
        AuthService,
        CalendarioService,
        CaricamentoMultimedialeService,
        DispositivoMobileService,
        LogService,
        MarcatoreService,
        ModuloAggiuntivoService,
        NotificaService,
        NumeroUtileService,
        OrganicoService,
        OtpService,
        PushNotificationService,
        RecapitoService,
        ScadenzeService,
        SelettivaService,
        SessionService,
        TestService,
        TetraService,
        ApiService,
        ApiConfiguration,
    ],
})
export class ApiModule {
    static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                {
                    provide: ApiConfiguration,
                    useValue: params,
                },
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error(
                'You need to import the HttpClientModule in your AppModule! \n' +
                    'See also https://github.com/angular/angular/issues/20575',
            );
        }
    }
}
