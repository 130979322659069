/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiCaricamentoMultimedialeAddUploadEventPost } from '../fn/caricamento-multimediale/api-caricamento-multimediale-add-upload-event-post';
import { ApiCaricamentoMultimedialeAddUploadEventPost$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-add-upload-event-post';
import { apiCaricamentoMultimedialeAuthWithCodeGet } from '../fn/caricamento-multimediale/api-caricamento-multimediale-auth-with-code-get';
import { ApiCaricamentoMultimedialeAuthWithCodeGet$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-auth-with-code-get';
import { apiCaricamentoMultimedialeCorpoConfiguratoListGet } from '../fn/caricamento-multimediale/api-caricamento-multimediale-corpo-configurato-list-get';
import { ApiCaricamentoMultimedialeCorpoConfiguratoListGet$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-corpo-configurato-list-get';
import { apiCaricamentoMultimedialeGdriveParentidFolderGet } from '../fn/caricamento-multimediale/api-caricamento-multimediale-gdrive-parentid-folder-get';
import { ApiCaricamentoMultimedialeGdriveParentidFolderGet$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-gdrive-parentid-folder-get';
import { apiCaricamentoMultimedialeGetGoogleAuthUrlPost } from '../fn/caricamento-multimediale/api-caricamento-multimediale-get-google-auth-url-post';
import { ApiCaricamentoMultimedialeGetGoogleAuthUrlPost$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-get-google-auth-url-post';
import { apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost } from '../fn/caricamento-multimediale/api-caricamento-multimediale-update-gdrive-parentid-folder-post';
import { ApiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-update-gdrive-parentid-folder-post';
import { apiCaricamentoMultimedialeUploadEventEndPost } from '../fn/caricamento-multimediale/api-caricamento-multimediale-upload-event-end-post';
import { ApiCaricamentoMultimedialeUploadEventEndPost$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-upload-event-end-post';
import { apiCaricamentoMultimedialeUploadMediaForEventPost } from '../fn/caricamento-multimediale/api-caricamento-multimediale-upload-media-for-event-post';
import { ApiCaricamentoMultimedialeUploadMediaForEventPost$Params } from '../fn/caricamento-multimediale/api-caricamento-multimediale-upload-media-for-event-post';
import { CorpoListItemDto } from '../models/corpo-list-item-dto';

@Injectable({ providedIn: 'root' })
export class CaricamentoMultimedialeService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiCaricamentoMultimedialeCorpoConfiguratoListGet()` */
    static readonly ApiCaricamentoMultimedialeCorpoConfiguratoListGetPath = '/api/caricamento-multimediale/corpo-configurato-list';

    /**
     * Carica la lista dei corpi abilitati al caricamento multimediale.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeCorpoConfiguratoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeCorpoConfiguratoListGet$Response(
        params?: ApiCaricamentoMultimedialeCorpoConfiguratoListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<CorpoListItemDto>>> {
        return apiCaricamentoMultimedialeCorpoConfiguratoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei corpi abilitati al caricamento multimediale.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeCorpoConfiguratoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeCorpoConfiguratoListGet(
        params?: ApiCaricamentoMultimedialeCorpoConfiguratoListGet$Params,
        context?: HttpContext,
    ): Observable<Array<CorpoListItemDto>> {
        return this.apiCaricamentoMultimedialeCorpoConfiguratoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<CorpoListItemDto>>): Array<CorpoListItemDto> => r.body),
        );
    }

    /** Path part for operation `apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost()` */
    static readonly ApiCaricamentoMultimedialeUpdateGdriveParentidFolderPostPath =
        '/api/caricamento-multimediale/update-gdrive-parentid-folder';

    /**
     * Aggiorna il valore per Google Drive parent id folder.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Response(
        params: ApiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiorna il valore per Google Drive parent id folder.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost(
        params: ApiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiCaricamentoMultimedialeGdriveParentidFolderGet()` */
    static readonly ApiCaricamentoMultimedialeGdriveParentidFolderGetPath = '/api/caricamento-multimediale/gdrive-parentid-folder';

    /**
     * Carica il valore per il folder parent id di GDrive.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeGdriveParentidFolderGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeGdriveParentidFolderGet$Response(
        params: ApiCaricamentoMultimedialeGdriveParentidFolderGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<string>> {
        return apiCaricamentoMultimedialeGdriveParentidFolderGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica il valore per il folder parent id di GDrive.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeGdriveParentidFolderGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeGdriveParentidFolderGet(
        params: ApiCaricamentoMultimedialeGdriveParentidFolderGet$Params,
        context?: HttpContext,
    ): Observable<string> {
        return this.apiCaricamentoMultimedialeGdriveParentidFolderGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<string>): string => r.body),
        );
    }

    /** Path part for operation `apiCaricamentoMultimedialeAddUploadEventPost()` */
    static readonly ApiCaricamentoMultimedialeAddUploadEventPostPath = '/api/caricamento-multimediale/add-upload-event';

    /**
     * Avvia una sessione di caricamento file su GDrive (Aggiorna il token, crea la cartella e ritorna l'id della sessione).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeAddUploadEventPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCaricamentoMultimedialeAddUploadEventPost$Response(
        params: ApiCaricamentoMultimedialeAddUploadEventPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<number>> {
        return apiCaricamentoMultimedialeAddUploadEventPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Avvia una sessione di caricamento file su GDrive (Aggiorna il token, crea la cartella e ritorna l'id della sessione).
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeAddUploadEventPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCaricamentoMultimedialeAddUploadEventPost(
        params: ApiCaricamentoMultimedialeAddUploadEventPost$Params,
        context?: HttpContext,
    ): Observable<number> {
        return this.apiCaricamentoMultimedialeAddUploadEventPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<number>): number => r.body),
        );
    }

    /** Path part for operation `apiCaricamentoMultimedialeUploadMediaForEventPost()` */
    static readonly ApiCaricamentoMultimedialeUploadMediaForEventPostPath = '/api/caricamento-multimediale/upload-media-for-event';

    /**
     * Carica un file multimediale legato ad un evento.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeUploadMediaForEventPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeUploadMediaForEventPost$Response(
        params: ApiCaricamentoMultimedialeUploadMediaForEventPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<string>> {
        return apiCaricamentoMultimedialeUploadMediaForEventPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica un file multimediale legato ad un evento.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeUploadMediaForEventPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeUploadMediaForEventPost(
        params: ApiCaricamentoMultimedialeUploadMediaForEventPost$Params,
        context?: HttpContext,
    ): Observable<string> {
        return this.apiCaricamentoMultimedialeUploadMediaForEventPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<string>): string => r.body),
        );
    }

    /** Path part for operation `apiCaricamentoMultimedialeUploadEventEndPost()` */
    static readonly ApiCaricamentoMultimedialeUploadEventEndPostPath = '/api/caricamento-multimediale/upload-event-end';

    /**
     * Al termine del caricamento multimediale notifica gli utenti che hanno il permesso di notifica abilitato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeUploadEventEndPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeUploadEventEndPost$Response(
        params: ApiCaricamentoMultimedialeUploadEventEndPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiCaricamentoMultimedialeUploadEventEndPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Al termine del caricamento multimediale notifica gli utenti che hanno il permesso di notifica abilitato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeUploadEventEndPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeUploadEventEndPost(
        params: ApiCaricamentoMultimedialeUploadEventEndPost$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiCaricamentoMultimedialeUploadEventEndPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiCaricamentoMultimedialeGetGoogleAuthUrlPost()` */
    static readonly ApiCaricamentoMultimedialeGetGoogleAuthUrlPostPath = '/api/caricamento-multimediale/get-google-auth-url';

    /**
     * Genera un url per l'autenticazione alle API di google drive.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeGetGoogleAuthUrlPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCaricamentoMultimedialeGetGoogleAuthUrlPost$Response(
        params: ApiCaricamentoMultimedialeGetGoogleAuthUrlPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<string>> {
        return apiCaricamentoMultimedialeGetGoogleAuthUrlPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Genera un url per l'autenticazione alle API di google drive.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeGetGoogleAuthUrlPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiCaricamentoMultimedialeGetGoogleAuthUrlPost(
        params: ApiCaricamentoMultimedialeGetGoogleAuthUrlPost$Params,
        context?: HttpContext,
    ): Observable<string> {
        return this.apiCaricamentoMultimedialeGetGoogleAuthUrlPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<string>): string => r.body),
        );
    }

    /** Path part for operation `apiCaricamentoMultimedialeAuthWithCodeGet()` */
    static readonly ApiCaricamentoMultimedialeAuthWithCodeGetPath = '/api/caricamento-multimediale/auth-with-code';

    /**
     * Esegue l'autenticazione tramite il codice ricevuto dal FE e salva il token e refresh token per l'utilizzo delle API di Google.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiCaricamentoMultimedialeAuthWithCodeGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeAuthWithCodeGet$Response(
        params: ApiCaricamentoMultimedialeAuthWithCodeGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiCaricamentoMultimedialeAuthWithCodeGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Esegue l'autenticazione tramite il codice ricevuto dal FE e salva il token e refresh token per l'utilizzo delle API di Google.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiCaricamentoMultimedialeAuthWithCodeGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiCaricamentoMultimedialeAuthWithCodeGet(
        params: ApiCaricamentoMultimedialeAuthWithCodeGet$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiCaricamentoMultimedialeAuthWithCodeGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }
}
